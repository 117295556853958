import axios from "axios";
import { getAPICall, postAPICall } from "./baseClient";

export const getUserData = async (
  payload: any = {
    page: 1,
    rowsPerPage: 10,
  }
) => {
  const { data: response } = await getAPICall(
    `/user?limit=${payload.rowsPerPage}&skip=${
      payload.page * payload.rowsPerPage
    }`
  );
  return response;
};
