import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserData } from "../api/userDataApi";
import Header from "../components/Header";
import InfoIcon from "@mui/icons-material/Info";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import Loader from "../components/Loader";

const Dashboard = () => {
  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = useState<any>(null);
  useEffect(() => {
    const getData = async () => {
      const data = await getUserData({ page, rowsPerPage });
      setData(data);
    };
    getData();
  }, [page, rowsPerPage]);

  if (!data) return <Loader />;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <Header />
      <div style={{ padding: 40, marginTop: 60 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  <span style={{ fontSize: 16, fontWeight: 600 }}>
                    Phone Number
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span style={{ fontSize: 16, fontWeight: 600 }}>Name</span>
                </TableCell>
                <TableCell align="center">
                  <span style={{ fontSize: 16, fontWeight: 600 }}>
                    Is Assessment Test Taken
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span style={{ fontSize: 16, fontWeight: 600 }}>
                    Get more info
                  </span>
                </TableCell>
              </TableRow>
              {data?.results?.map((row: any) => (
                <TableRow key={row.phoneNumber}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="center">{row.phoneNumber}</TableCell>
                  <TableCell align="center">
                    {row.isAssessmentTestTaken ? "Yes" : "No"}
                  </TableCell>
                  <TableCell align="center">
                    {row.isAssessmentTestTaken && (
                      <IconButton
                        onClick={() => {
                          navigate(`/user/${row._id}`);
                        }}
                      >
                        <InfoIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={3}
                  count={data.totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
export default Dashboard;
