import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUserDetails } from "../api/userDetailsApi";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Header from "../components/Header";
import "./UserDetails.css";
import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { getPlanDetails, submitPlan } from "../api/plan";
import Loader from "../components/Loader";

const UserDetails = () => {
  const { id } = useParams();
  const [userDetails, setUserDetails] = useState();
  const [planDetails, setPlanDetails] = useState<any[]>([]);

  const [plan, setPlan] = useState<any>(null);

  const handleChange = (event: SelectChangeEvent) => {
    // @ts-ignore
    setPlan(event.target.value);
  };

  const handlePlanSubmit = async () => {
    try {
      const payload = {
        planId: plan?._id,
        userId: id,
      };
      const planSubmit = await submitPlan(payload);
      console.log(planSubmit);
      getUserDetailsData();
    } catch (error) {
      alert("Plan not submitted");
    }
  };

  const getUserDetailsData = async () => {
    const data = await getUserDetails({ id });
    setUserDetails(data);
  };
  const getPlanDetailsData = async () => {
    const data = await getPlanDetails({ id });
    setPlanDetails(data?.data?.plans);
  };

  const getPlanName = (planId: string) => {
    const plan = planDetails.find((plan) => plan._id === planId);
    return plan?.title;
  };

  useEffect(() => {
    getPlanDetailsData();
    getUserDetailsData();
  }, []);

  if (!userDetails || !planDetails) return <Loader />;

  const { data } = userDetails;
  const { assessmentResponse, planRecommendation, planEnrollment } = data;
  if (!assessmentResponse)
    return <div style={{ padding: 50, fontWeight: 600 }}>No data</div>;
  const { anxietyScore, depressionScore, insomniaScore, response } =
    assessmentResponse;

  const UserScore = () => {
    return (
      <div className={"progressOuterContainer"}>
        <div className={"progressContainer"}>
          <CircularProgressbar
            styles={buildStyles({
              strokeLinecap: "round",
              textSize: "20px",
              pathColor: `#f88`,
              textColor: "#f88",
            })}
            value={Math.ceil(anxietyScore)}
            text={`${Math.ceil(anxietyScore)}%`}
          />
          <p>
            <b>Anxiety Score</b>
          </p>
        </div>
        <Divider orientation="vertical" flexItem />
        <div className={"progressContainer"}>
          <CircularProgressbar
            styles={buildStyles({
              strokeLinecap: "round",
              textSize: "20px",
            })}
            value={Math.ceil(depressionScore)}
            text={`${Math.ceil(depressionScore)}%`}
          />
          <p>
            <b>Depression Score</b>
          </p>
        </div>
        <Divider orientation="vertical" flexItem />
        <div className={"progressContainer"}>
          <CircularProgressbar
            value={Math.ceil(insomniaScore)}
            text={`${Math.ceil(insomniaScore)}%`}
            styles={buildStyles({
              strokeLinecap: "round",
              textSize: "20px",
              pathColor: `lightgreen`,
              textColor: "lightgreen",
            })}
          />
          <p>
            <b>Insomnia Score</b>
          </p>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Header title={"User Details"} />
      <UserScore />
      <div style={{ padding: 40 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  <span style={{ fontSize: 16, fontWeight: 600 }}>
                    Question
                  </span>
                </TableCell>
                <TableCell align="center">
                  <span style={{ fontSize: 16, fontWeight: 600 }}>Answer</span>
                </TableCell>
              </TableRow>
              {/* @ts-ignore */}
              {response.map((row) => (
                <TableRow key={row.phoneNumber}>
                  <TableCell scope="row">{row.questionTitle}</TableCell>
                  <TableCell style={{ width: 200 }} scope="row" align="center">
                    {row.answer.title}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {!planEnrollment ? (
        <>
          {planRecommendation && (
            <div style={{ marginLeft: 60 }}>
              <p style={{ fontSize: 16, fontWeight: 600 }}>
                {`Recommended Plan: ${getPlanName(
                  // @ts-ignore
                  planRecommendation?.planId
                )}`}
              </p>
            </div>
          )}
          <div className="plansContainer">
            <FormControl
              style={{ width: 200, marginLeft: 60, display: "flex" }}
            >
              <InputLabel id="demo-simple-select-label">Select Plan</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={plan ?? ""}
                label="Select Plan"
                onChange={handleChange}
              >
                {planDetails?.map((plan) => {
                  return (
                    <MenuItem
                      key={plan._id}
                      value={plan}
                    >{`${plan.title} (₹${plan.priceInINR})`}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <Button
              onClick={() => {
                handlePlanSubmit();
              }}
              variant="contained"
              style={{ marginLeft: 40 }}
            >
              {planRecommendation ? "Edit Plan" : "Submit Plan"}
            </Button>
          </div>
        </>
      ) : (
        <div className="planAlreadySelected">{`Plan Enrolled:-  ${getPlanName(
          // @ts-ignore
          planEnrollment?.planId
        )}`}</div>
      )}
    </div>
  );
};

export default UserDetails;
