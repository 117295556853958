import { getAPICall, postAPICall } from "./baseClient";

export const getPlanDetails = async (payload: { id: string | undefined }) => {
  const { data: response } = await getAPICall(`/plans`);
  return response;
};

export const submitPlan = async (payload: any) => {
  const { data: response } = await postAPICall(`/plans/recommend`, payload);
  return response;
};
