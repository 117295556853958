import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { auth } from "../firebase";

const Splash = () => {
  const navigate = useNavigate();

  const [user, loading, isError] = useAuthState(auth);
  if (loading) return <Loader />;
  if (user) {
    navigate("/dashboard");
  } else {
    navigate("/login");
  }

  return <div>{isError && <div>Error</div>}</div>;
};

export default Splash;
