import React from "react";
import "./App.css";
import "./firebase";

import { Navigate, Route, Routes } from "react-router-dom";
import LoginScreen from "./Screens/Login";
import Dashboard from "./Screens/Dashboard";
import Reset from "./Screens/Reset";
import UserDetails from "./Screens/UserDetails";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase";
import Splash from "./Screens/Splash";
import Loader from "./components/Loader";

function App() {
  const ProtectedRoute = (props: any) => {
    const [user, loading] = useAuthState(auth);

    const { children } = props;
    if (loading) return <Loader />;
    if (!user) {
      return <Navigate to="/login" replace />;
    }
    return children;
  };

  return (
    <Routes>
      <Route path="/" element={<Splash />} />
      <Route path="/login" element={<LoginScreen />} />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route path="/reset" element={<Reset />} />
      <Route
        path="/user/:id"
        element={
          <ProtectedRoute>
            <UserDetails />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

export default App;
