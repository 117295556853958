// API Axios Get Call.
import axios from "axios";

export const API_ENDPOINT =
  "https://dolphin-app-49aza.ondigitalocean.app/api/v1";
export const getAPICall = (url: string, headers: any = {}) => {
  let headersData = {};
  if (headers) {
    headersData = { ...headers };
  }
  const token = localStorage.getItem("token");
  if (token) {
    headersData = {
      ...headersData,
      authorization: `Bearer ${token}`,
    };
  }

  return axios.get(`${API_ENDPOINT}${url}`, {
    headers: headersData,
  });
};
// API Axios Post Call.
export const postAPICall = (url: string, data: any, headers: any = {}) => {
  let headersData = {};
  if (headers) {
    headersData = { ...headers };
  }
  const token = localStorage.getItem("token");
  if (token) {
    headersData = {
      ...headersData,
      authorization: `Bearer ${token}`,
    };
  }

  return axios.post(`${API_ENDPOINT}${url}`, data, {
    headers: headersData,
  });
};
// API Axios Put Call.
export const putAPICall = (url: string, data: any) => {
  return axios.put(`${API_ENDPOINT}${url}`, data);
};
// API Axios Delete Call.
export const deleteAPICall = (url: string) => {
  return axios.delete(`${API_ENDPOINT}${url}`);
};
