import React, { useState } from "react";
import { sendPasswordReset } from "../firebase";
import "./Reset.css";
const Reset = () => {
  const [email, setEmail] = useState("");
  return (
    <div className="reset">
      <div className="reset__container">
        <input
          type="text"
          className="reset__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <button className="reset__btn" onClick={() => sendPasswordReset(email)}>
          Send password reset email
        </button>
      </div>
    </div>
  );
};
export default Reset;
