import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import React from "react";
import { auth, logout } from "../firebase";
import LogoutIcon from "@mui/icons-material/Logout";

interface HeaderProps {
  title?: string;
}

const Header = (props: HeaderProps) => {
  const { title } = props;
  return (
    <AppBar position="fixed">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {title ?? "Stimveda Users"}
        </Typography>
        {auth && (
          <div>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={logout}
            >
              <LogoutIcon />
            </IconButton>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
