import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDSlTHMvTYx6aN9JNVWk1d6SiRz7OfpbV4",
  authDomain: "stimveda-dashboard.firebaseapp.com",
  projectId: "stimveda-dashboard",
  storageBucket: "stimveda-dashboard.appspot.com",
  messagingSenderId: "486046386511",
  appId: "1:486046386511:web:3f580c84672b904f269d3a",
  measurementId: "G-0CDVDVE1L5",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const logInWithEmailAndPassword = async (email: any, password: any) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err: any) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err: any) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};
export { auth, logInWithEmailAndPassword, sendPasswordReset, logout };
